// extracted by mini-css-extract-plugin
export var border1 = "cK_hq";
export var border2 = "cK_hr";
export var border3 = "cK_hs";
export var border4 = "cK_ht";
export var container = "cK_f";
export var dark = "cK_dk";
export var large = "cK_cb";
export var ldsRing = "cK_hv";
export var light = "cK_dj";
export var medium = "cK_cc";
export var primaryPressed = "cK_hw";
export var xlarge = "cK_hp";