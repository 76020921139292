// extracted by mini-css-extract-plugin
export var centeredTitle = "cP_hG";
export var childrenContainer = "cP_hJ";
export var container = "cP_f";
export var dark = "cP_dk";
export var innerContainer = "cP_cy";
export var left = "cP_cG";
export var light = "cP_dj";
export var noBottomPadding = "cP_hB";
export var noRectangle = "cP_hF";
export var noTopPadding = "cP_hC";
export var overflowHidden = "cP_hD";
export var right = "cP_cH";
export var subtitle = "cP_k";
export var textContainer = "cP_b0";
export var textContainerCustomWidth = "cP_hH";